
import Vue from "vue";
import i18n from "@/i18n";
import { UserCreate } from "@/models/usercreate";
import { Snackbar } from "@/models/snackbar";
import { ComboBox } from "@/models/combobox";

export default Vue.extend({
  name: "RegisterUser",
  data: () => ({
    tempUserCreate: {
      email: "",
      organization: "",
      organizationId: null,
      organizationName: "",
      organizationCity: "",
      organizationCountryId: null,
      firstName: "",
      lastName: "",
      phone: "",
      roleInOrganization: "",
      roleId: null,
    } as UserCreate,
    isFormValid: true,
    isLoadingCreateUser: false,
    rules: {
      name: [
        (v: string) => !!v || i18n.t("rules.required"),
        (v: string) => (v && v.length >= 3) || i18n.t("rules.morethan3char"),
      ],
      role: [
        (v: string) => !!v || i18n.t("rules.required"),
        (v: string) => (v && v.length >= 2) || i18n.t("rules.morethan2char"),
      ],
      email: [
        (v: string) => !!v || i18n.t("rules.required"),
        (v: string) => /.+@.+\..+/.test(v) || i18n.t("rules.emailmustbevalid"),
      ],
      select: [(v: string) => !!v || i18n.t("rules.required")],
    },
  }),
  computed: {
    organizationRule() {
      return [
        (v: string | ComboBox) =>
          !!v || this.tempUserCreate.roleId != 3 || i18n.t("rules.required"),
        (v: unknown) =>
          typeof v !== "string" ||
          (v && (v as string).length >= 2) ||
          this.tempUserCreate.roleId != 3 ||
          i18n.t("rules.morethan2char"),
      ];
    },
    userCreateRes() {
      return this.$store.getters["user/getUserCreateRes"];
    },
    countries() {
      return this.$store.getters["datamanager/getCountries"];
    },
    roles() {
      return this.$store.getters["user/getRoles"];
    },
    organizations() {
      return this.$store.getters["user/getOrganization"];
    },
    lang: {
      get() {
        return i18n ? i18n.locale : "en";
      },
    },
  },
  methods: {
    reset() {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
    },
    async fetchRoles() {
      await this.$store.dispatch("user/fetchRoles");
    },
    async fetchCountries() {
      await this.$store.dispatch("datamanager/fetchCountries");
    },
    async fetchOrganizations() {
      await this.$store
        .dispatch(
          "user/fetchOrganizations",
          this.tempUserCreate.organizationCountryId
        )
        .then((this.tempUserCreate.organization = null));
    },
    triggerSnack(snack: Snackbar) {
      this.$store.dispatch("general/changeSnackbar", snack);
    },
    async register() {
      if (this.tempUserCreate.roleId != 3) {
        this.tempUserCreate.organizationId = null;
        this.tempUserCreate.organizationName = "";
        this.tempUserCreate.roleInOrganization = "";
      } else {
        if (typeof this.tempUserCreate.organization === "string") {
          this.tempUserCreate.organizationId = null;
          this.tempUserCreate.organizationName =
            this.tempUserCreate.organization;
        } else {
          this.tempUserCreate.organizationId = (
            this.tempUserCreate.organization as ComboBox
          ).id;
          this.tempUserCreate.organizationName = (
            this.tempUserCreate.organization as ComboBox
          ).name;
        }
      }

      this.isFormValid = (
        this.$refs.form as Vue & {
          validate: () => boolean;
        }
      ).validate();

      if (this.isFormValid) {
        this.isLoadingCreateUser = true;
        await this.$store
          .dispatch("user/userCreate", this.tempUserCreate)
          .then((e) => {
            this.isLoadingCreateUser = false;
            let snack = {
              isSnacking: true,
              snackColor: "success",
              snackMessage: this.$t("basic.savesuccess").toString(),
              snackTimeout: "2000",
            };
            this.triggerSnack(snack);
            this.reset();
          })
          .catch((err) => {
            this.isLoadingCreateUser = false;
            if (err.response.status == 422) {
              let snack = {
                isSnacking: true,
                snackColor: "orange",
                snackMessage:
                  this.$t("basic.savewarning").toString() +
                  err.response.data.message,
                snackTimeout: "3000",
              };
              this.triggerSnack(snack);
            } else {
              let snack = {
                isSnacking: true,
                snackColor: "error",
                snackMessage:
                  this.$t("basic.saveerror").toString() +
                  err.response.data.message,
                snackTimeout: "3000",
              };
              this.triggerSnack(snack);
            }
            return err.response.status as Number;
          });
      }
    },
  },
  created() {
    this.fetchRoles();
    this.fetchCountries();
  },
});
